var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-row",
        [
          _c(
            "el-col",
            { staticClass: "lenhi", attrs: { span: 8 } },
            [
              _c("span", [_vm._v("陈列类型：")]),
              _c(
                "el-select",
                {
                  attrs: { placeholder: "请选择", disabled: "" },
                  model: {
                    value: _vm.displayType,
                    callback: function ($$v) {
                      _vm.displayType = $$v
                    },
                    expression: "displayType",
                  },
                },
                _vm._l(_vm.displayTypeSelect, function (item) {
                  return _c("el-option", {
                    key: item.value,
                    attrs: { label: item.label, value: item.value },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 8 } },
            [
              _c("span", { staticClass: "textalign" }, [
                _vm._v("陈列示例图片："),
              ]),
              _vm._l(_vm.imgArr, function (example, index) {
                return _c("el-image", {
                  key: index,
                  staticStyle: { width: "100px", height: "100px" },
                  attrs: { src: example, fit: "cover" },
                })
              }),
            ],
            2
          ),
        ],
        1
      ),
      _c(
        "div",
        [
          _c("span", [_vm._v("陈列产品维度：")]),
          _c("el-input", {
            staticStyle: { width: "15%", display: "inline-block" },
            attrs: { placeholder: "请输入内容", disabled: "" },
            model: {
              value: _vm.productDimension,
              callback: function ($$v) {
                _vm.productDimension = $$v
              },
              expression: "productDimension",
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        [
          _c("span", [_vm._v("产品：")]),
          _vm._l(_vm.product, function (profuctData, index) {
            return _c("el-tag", { key: index }, [_vm._v(_vm._s(profuctData))])
          }),
        ],
        2
      ),
      _c(
        "vxe-table",
        { attrs: { data: _vm.tableData } },
        [
          _c("vxe-table-column", {
            attrs: { field: "signatory", title: "陈列面达标个数" },
          }),
          _c("vxe-table-column", {
            attrs: { field: "signatoryName", title: "月度奖励类型" },
          }),
          _c("vxe-table-column", {
            attrs: { field: "signatoryCode", title: "月度奖励额度" },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
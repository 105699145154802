<script>
// import TablePage from '../../../../../components/table_page';
import TablePage from './table';

export default {
  extends: TablePage,
  components: {
    // TablePage,
  },
  data() {
    return {
    };
  },
};
</script>

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "box" }, [
    _c("div", { staticClass: "minBox" }, [
      _c("div", { staticClass: "textclass" }, [
        _c("span", [_vm._v("第一次上传图片")]),
        _c("span", [_vm._v(_vm._s(_vm.oneTime))]),
      ]),
      _c(
        "div",
        { staticClass: "imgFlex" },
        _vm._l(_vm.one, function (img) {
          return _c(
            "el-image",
            {
              key: img.id,
              attrs: {
                fit: "scale-down",
                "preview-src-list": _vm.one,
                src: img,
              },
            },
            [
              _c(
                "div",
                {
                  staticClass: "image-slot",
                  attrs: { slot: "placeholder" },
                  slot: "placeholder",
                },
                [
                  _vm._v("加载中"),
                  _c("span", { staticClass: "dot" }, [_vm._v("...")]),
                ]
              ),
            ]
          )
        }),
        1
      ),
    ]),
    _c("div", { staticClass: "minBox" }, [
      _c(
        "div",
        {
          class:
            _vm.two.length === 0
              ? (_vm.imgExhibit = "nullImg")
              : (_vm.imgExhibit = "noNone" + " textclass"),
        },
        [
          _c("span", [_vm._v("第二次上传图片")]),
          _c("span", [_vm._v(_vm._s(_vm.twoTime))]),
        ]
      ),
      _c(
        "div",
        { staticClass: "imgFlex" },
        _vm._l(_vm.two, function (img) {
          return _c(
            "el-image",
            {
              key: img.id,
              attrs: {
                fit: "scale-down",
                "preview-src-list": _vm.two,
                src: img,
              },
            },
            [
              _c(
                "div",
                {
                  staticClass: "image-slot",
                  attrs: { slot: "placeholder" },
                  slot: "placeholder",
                },
                [
                  _vm._v("加载中"),
                  _c("span", { staticClass: "dot" }, [_vm._v("...")]),
                ]
              ),
            ]
          )
        }),
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<!--
 * @Author: 冯杰
 * @Date: 2021-08-13 09:21:44
 * @LastEditTime: 2021-09-09 16:13:54
 * @FileName: 文件名
-->
<template>
  <div>
    <el-row>
      <el-col :span="7">
        <span>第一次上传时间：</span>
        <span>2021-05-13 18:00:00</span>
      </el-col>
      <el-col :span="7">
        <span>第一次上传人：</span>
        <span>xxxx（13139900200）</span>
      </el-col>
      <el-col :span="7">
        <span>AI审核结果：</span>
        <span>驳回</span>
      </el-col>
    </el-row>
    <el-row class="twomargin">
        <span>第一次上传图片：</span>
      <el-col :span="7" v-for="imgdata in one" :key="imgdata.id">
        <el-image :src="imgdata.pictureUrl"></el-image>
      </el-col>
    </el-row>
    <el-row >
      <el-col :span="7">
        <span>第二次上传时间：</span>
        <span>2021-05-13 18:00:00</span>
      </el-col>
      <el-col :span="7">
        <span>第二次上传人：</span>
        <span>xxxx（13139900200）</span>
      </el-col>
      <el-col :span="7">
        <span>AI审核结果：</span>
        <span>不合格</span>
      </el-col>
    </el-row>
    <el-row class="twomargin">
        <span>第二次上传图片：</span>
      <el-col :span="7" v-for="imgdata in one" :key="imgdata.id">
        <el-image :src="imgdata.pictureUrl"></el-image>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="8">
        <span>最终审核结果：</span>
        <el-select v-model="value" placeholder="请选择">
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-col>
      <el-col :span="1">
        <span>备注：</span>
      </el-col>
      <el-col :span="15">
          <el-input type="textarea" :rows="5" placeholder="请确认最终审核结果" v-model="textarea"></el-input>
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  data() {
    return {
      options: [
        {
          value: '1',
          label: '合格',
        },
        {
          value: '2',
          label: '不合格',
        },
      ],
      value: '',
      textarea: '',
      one: [],
      two: [],
      businessCode: '',
    };
  },
  props: {
    rowData: Object,
  },
  watch: {
    rowData(data) {
      this.businessCode = data.businessCode;
      data.displayTaskUploads.map((v) => {
        const item = v;
        if (item.uploadNumber === 1) {
        // 第一次
          this.one.push(item);
        } else {
        // 第二次
          this.two.push(item);
        }
        return item;
      });
    },
  },
  mounted() {
  },
  methods: {
  },
};
</script>

<style lang="less" scoped>
span{
    font-size: 14px;
}
.el-image {
  width: 200px;
  height: 150px;
  vertical-align: top;
}

.twomargin {
  margin: 20px 0;
}

.el-select{
    display: inline-block;
}

/deep/ .el-textarea__inner{
    width: 650px;
    display: inline-block;
}
</style>

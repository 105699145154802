<script>
// import TablePage from ' ../../  ../../../../components/table_page';
import TablePage from '../../../../../../components/table_page';
import Form from '../form';
// import Modal from '../../../../../../components/modal';

import request from '../../../../../../utils/request.js';

export default {
  extends: TablePage,
  data() {
    return {
      requestType: 'GET',
      requestUrl: '/cps/v1/gift/rewardGiftDetail/findRecordByConditions',
      formName: 'Form',
      formConfig: {},
      modalConfig: {
        title: '新增',
        visible: false,
        type: '',
      },
    };
  },
  components: {
    Form,
  },
  methods: {
    // 按钮点击事件
    modalClick({ val, row }) {
      console.log(11111, val);
      if (!this.beforeModalClick({ val, row })) return;
      this.formConfig = {};
      if (val.code === 'add') {
        this.formName = 'Form';
        this.formConfig = {
          code: 'add',
        };
        this.modalConfig.title = '新增';
        this.openFull();
      }
      if (val.code === 'edit') {
        // 如果分类层级为启用状态则不可编辑
        if (row.fullName === '1111') {
          this.formConfig = { ...row, code: 'view' };
        } else {
          this.formConfig = { ...row, code: 'edit' };
        }
        this.formName = 'Form';
        this.modalConfig.title = '编辑';
        this.openFull();
      }
      // 查看
      if (val.code === 'check_photo') {
        this.formName = 'Form';
        this.formConfig = { ...row, btnCode: 'view', code: 'view' };
        this.modalConfig.title = '查看';
        this.openFull();
      }
    },

  },
  created() {
    this.getConfigList('stores_resources_list');
  },
};
</script>

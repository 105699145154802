var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "header-box" }, [
    _c("div", { staticClass: "zhixing" }, [
      _c("span", { staticClass: "test" }, [_vm._v("当月任务相关终端数")]),
      _c("span", { staticClass: "money" }, [
        _vm._v(_vm._s(_vm.implementTerminal)),
      ]),
    ]),
    _c("div", { staticClass: "dacheng" }, [
      _c("span", { staticClass: "test" }, [_vm._v("已执行终端数")]),
      _c("span", { staticClass: "money" }, [_vm._v(_vm._s(_vm.reachTerminal))]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
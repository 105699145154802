/*
 * @Author: 冯杰
 * @Date: 2021-08-05 09:47:49
 * @LastEditors: yzr
 * @LastEditTime: 2021-09-28 15:06:19
 * 陈列任务表格
 */
import TablePage from '@/found/components/table_page';
import Modal from '@/found/components/modal';
import request from '@/found/utils/request';
import Form from './form/index.js';
import Photo from './photo/index.js';
import ElCity from '../../../components/city.vue';
import Tree from './components/tree.vue';

export default {
  name: 'displayTask',
  extends: TablePage,
  data() {
    return {
      requestUrl: '/cps/v1/web/displayPolicyReport/findByConditions',
      requestType: 'GET',
      params: {
        // taskStatus: '2',
      },
      isCalculateHeight: false,
    };
  },
  components: {
    Modal,
    Form,
    ElCity,
    Tree,
    Photo,
  },
  watch: {
    code(val, oldVal) {
      let parvalue = null;
      switch (this.code) {
        case 'all':
          parvalue = {};
          this.getData(parvalue);
          break;
        case 'notStarted':
          parvalue = {
            taskStatus: '1',
          };
          this.getData(parvalue);
          break;
        case 'ToBeExecuted':
          parvalue = {
            taskStatus: '2',
          };
          this.getData(parvalue);
          break;
        case 'pass':
          parvalue = {
            taskStatus: '5',
          };
          this.getData(parvalue);
          break;
        case 'fail':
          parvalue = {
            taskStatus: '6',
          };
          this.getData(parvalue);
          break;

        default:
          break;
      }
    },
  },
  methods: {
    beforeSetSearch(val) {
      const item = val;
      if (item.field === 'city') {
        item.typeName = 'ElCity';
        item.type = 'customSearch';
      }

      if (item.field === 'orgName') {
        item.typeName = 'Tree';
        item.type = 'customSearch';
      }
      return item;
    },

    setColumn(v) {
      const rowData = v;
      return rowData;
    },

    modalClick({ val, row }) {
      this.formConfig = {
        ...this.formConfig,
        code: val.code,
      };
      if (val.code === 'check_photo') {
        this.modalConfig.title = '陈列照片';
        this.formName = 'Photo';
        this.formConfig.busCode = row.businessCode;
        this.modalConfig.height = '584px';
        this.modalConfig.width = '620px';
        this.formConfig.buttonCode = 'check_photo';
        this.openModal();
      } else if (val.code === 'review') {
        this.modalConfig.title = '陈列任务';
        this.formName = 'Form';
        this.formConfig.businessCode = row.businessCode;
        this.formConfig.buttonCode = 'review';
        this.openFull();
      } else if (val.code === 'view') {
        // 跳转协议模板
        this.$router.push({
          name: 'modules',
          params: {
            templateCode: row.templateId,
          },
        });
      }
    },
    cityData(val) {
      if (val) {
        console.log(val);
        this.searchFormData = {
          city: val,
        };
      }
    },
    getData(params) {
      request.get('/cps/v1/web/displayPolicyReport/findByConditions', params).then((res) => {
        if (!res.success) return;
        this.tableData = res.result.records;
      });
    },
    clickVisible({ val: { code }, row }) {
      console.log(111, code);
      if (
        code === 'review'
        && (row.taskStatus !== '4')
      ) {
        return false;
      }
      if (
        code === 'check_photo'
        && (row.taskStatus === '1' || row.taskStatus === '2' || row.taskStatus === '7')
      ) {
        return false;
      }
      return true;
    },
  },
  created() {
    // 重写获取配置信息
    this.getConfigList('displayTaskList');
  },
};

<!--
 * @Author: 冯杰
 * @Date: 2021-08-12 18:59:58
 * @LastEditTime: 2021-09-09 21:30:29
 * @FileName: 陈列分利政策
-->
<template>
  <div>
    <el-row>
      <el-col :span="8" class="lenhi">
        <span>陈列类型：</span>
        <el-select v-model="displayType" placeholder="请选择" disabled>
          <el-option
            v-for="item in displayTypeSelect"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-col>
      <el-col :span="8">
        <span class="textalign">陈列示例图片：</span>
        <el-image style="width: 100px; height: 100px" :src="example" fit="cover" v-for="(example,index) in imgArr" :key="index"></el-image>
      </el-col>
    </el-row>
    <div>
      <span>陈列产品维度：</span>
      <el-input v-model="productDimension" placeholder="请输入内容" disabled style="width:15%;display:inline-block;"></el-input>
    </div>
    <div>
      <span>产品：</span>
      <el-tag v-for="(profuctData,index) in product" :key="index">{{profuctData}}</el-tag>
    </div>
    <vxe-table :data="tableData">
    <vxe-table-column field="signatory" title="陈列面达标个数"></vxe-table-column>
    <vxe-table-column field="signatoryName" title="月度奖励类型"></vxe-table-column>
    <vxe-table-column field="signatoryCode" title="月度奖励额度"></vxe-table-column>
  </vxe-table>
  </div>
</template>

<script>
export default {
  data() {
    return {
      options: [
        {
          value: '0',
          label: '全部产品',
        },
        {
          value: '1',
          label: '按品牌',
        },
        {
          value: '2',
          label: '按系列',
        },
        {
          value: '3',
          label: '按大类',
        },
        {
          value: '4',
          label: '按细类',
        },
        {
          value: '5',
          label: '按产品组',
        },
        {
          value: '6',
          label: '按单品',
        },
      ],
      displayTypeSelect: [
        {
          value: '1',
          label: '货架',
        },
        {
          value: '2',
          label: '堆头',
        },
      ],
      displayType: '',
      productDimension: '',
      product: [], // 产品
      number: '', // 达标个数
      url: '',
      tableData: [],
      displayData: {},
      imgArr: [],
    };
  },
  props: {
    rowData: Object,
  },
  methods: {},
  watch: {
    rowData(data) {
      this.url = data.displayConfigurations.displaySampleGraph;
      this.displayType = data.displayType;
      data.displayConfigurations.map((v) => {
        const item = v;
        this.productDimension = item.dimensionName;
        if (item.dimensionName === '全部') {
          this.product = [];
        } else {
          item.displayPolicyRanges.map((val) => { // 3条数据
            const value = val;
            this.product.push(value.specialName);
            return value;
          });
        }
        item.displayPolicyExpressions.map((res) => { // 4条数据
          const result = res;
          const table = {};
          table.signatory = result.displayNumber;// 达标个数
          table.signatoryName = result.rewardMethodName; // 奖励类型
          table.signatoryCode = result.awardConditionFlag; // 奖励额度
          this.tableData.push(table);
          return result;
        });
        return item;
      });
      data.displaySampleGraphs.map((img) => {
        const image = img;
        this.imgArr.push(image.pictureUrl);
        return image;
      });
    },
  },
};
</script>

<style lang="less" scoped>
.el-row {
  margin-bottom: 10px;
}
.lenhi {
  // line-height: 100px;
  transform: translateY(30%);
}
.el-select {
  display: inline-block;
}

span {
  font-size: 14px;
}

.el-image {
  display: inline-block;
  width: 100px;
  height: 100px;
  vertical-align: middle;
}
.textalign {
  display: inline-block;
}
.borderline {
  border: 1px solid #eee;
}
.el-tag {
  width: 5%;
  margin: 10px;
}
</style>

<!--
 * @Author: 冯杰
 * @Date: 2021-08-05 10:43:43
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2021-09-09 20:25:51
 * @FilePath: 头部看板
-->
<template>
    <div class="header-box">
        <div class="zhixing">
            <span class="test">当月任务相关终端数</span>
            <span class="money">{{implementTerminal}}</span>
        </div>
        <div class="dacheng">
            <span class="test">已执行终端数</span>
            <span class="money">{{reachTerminal}}</span>
        </div>
        <!-- <div class="jifen">
            <span class="test">已分利积分</span>
            <span class="money">{{integral}}</span>
        </div>
        <div class="hongbao">
            <span class="test">已分利红包</span>
            <span class="money">{{redEnvelope}}</span>
        </div>
        <div class="feiyong">
            <span class="test">已分利费用</span>
            <span class="money">{{fee}}</span>
        </div> -->
    </div>
</template>

<script>
import request from '@/found/utils/request';
import { unitConvert } from '../../../../components/moneyNumber.js';

export default {
  data() {
    return {
      implementTerminal: null, // 执行终端数
      reachTerminal: null, // 已达成终端数
      integral: null, // 已分利积分
      redEnvelope: null, // 已分利红包
      fee: null, // 已分利费用
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    getData() {
      request
        .get('/cps/v1/web/displayPolicyReport/findDisplayStatisticsReportByTenant')
        .then((res) => {
          if (!res.success) return;
          const obj = res.result;
          this.implementTerminal = obj.executionTerminalNumbers;
          this.reachTerminal = obj.finishTerminalNumbers;
          obj.rewardTypeStatisticsVos.map((v) => {
            const item = v;
            if (item.key === 'redpacket') { // 红包
              obj.redEnvelope = item.amount;
            }
            if (item.key === 'integral') { // 积分
              obj.integral = item.amount;
            }
            if (item.key === 'cost') { // 费用
              obj.fee = item.amount;
            }
            return item;
          });
          this.redEnvelope = unitConvert(obj.redEnvelope);
          this.integral = obj.integral;
          this.fee = unitConvert(obj.fee);
        });
    },
  },
};
</script>

<style lang="less" scoped>
.header-box{
    min-width: 1431px;
    width: 100%;
    height: 216px;
    display: flex;
    // justify-content: space-between;
    align-items: center;
    div{
        margin-right: 24px;
        width: 20%;
        height: 168px;
        font-family: PingFangSC-Regular;
        color: #333333;
        border-radius: 4px;
        background-image: linear-gradient(-45deg, #EEF6FF 0%, #FFFFFF 100%);
        box-shadow: 0px 0px 10px 0px rgba(14,93,195,0.1);
        span{
            display: block;
            font-size: 16px;
        }
        .test{
            height: 24px;
            line-height: 24px;
            margin: 30px 0 12px 20px;
        }
        .money{
            height: 58px;
            line-height: 58px;
            font-size: 50px;
            color: #0E5DC3;
            font-weight: 500;
            margin-left: 20px;
        }
    }
    .zhixing{
        background: url('~@/found/assets/img/cpsimg/zhixing.png') no-repeat;
        background-size: cover;
    }
    .dacheng{
        background: url('~@/found/assets/img/cpsimg/dacheng.png') no-repeat;
        background-size: cover;
        .money{
            color: #F79435;
        }
    }
    .jifen{
        background: url('~@/found/assets/img/cpsimg/jifen.png') no-repeat;
        background-size: cover;
        .money{
            color: #28B795;
        }
    }
    .hongbao{
        background: url('~@/found/assets/img/cpsimg/hongbao.png') no-repeat;
        background-size: cover;
    }
    .feiyong{
        background: url('~@/found/assets/img/cpsimg/feiyong.png') no-repeat;
        background-size: cover;
        .money{
            color: #F79435;
        }
        margin-right: 0;
    }
}
</style>

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-row",
        [
          _c("el-col", { attrs: { span: 7 } }, [
            _c("span", [_vm._v("第一次上传时间：")]),
            _c("span", [_vm._v("2021-05-13 18:00:00")]),
          ]),
          _c("el-col", { attrs: { span: 7 } }, [
            _c("span", [_vm._v("第一次上传人：")]),
            _c("span", [_vm._v("xxxx（13139900200）")]),
          ]),
          _c("el-col", { attrs: { span: 7 } }, [
            _c("span", [_vm._v("AI审核结果：")]),
            _c("span", [_vm._v("驳回")]),
          ]),
        ],
        1
      ),
      _c(
        "el-row",
        { staticClass: "twomargin" },
        [
          _c("span", [_vm._v("第一次上传图片：")]),
          _vm._l(_vm.one, function (imgdata) {
            return _c(
              "el-col",
              { key: imgdata.id, attrs: { span: 7 } },
              [_c("el-image", { attrs: { src: imgdata.pictureUrl } })],
              1
            )
          }),
        ],
        2
      ),
      _c(
        "el-row",
        [
          _c("el-col", { attrs: { span: 7 } }, [
            _c("span", [_vm._v("第二次上传时间：")]),
            _c("span", [_vm._v("2021-05-13 18:00:00")]),
          ]),
          _c("el-col", { attrs: { span: 7 } }, [
            _c("span", [_vm._v("第二次上传人：")]),
            _c("span", [_vm._v("xxxx（13139900200）")]),
          ]),
          _c("el-col", { attrs: { span: 7 } }, [
            _c("span", [_vm._v("AI审核结果：")]),
            _c("span", [_vm._v("不合格")]),
          ]),
        ],
        1
      ),
      _c(
        "el-row",
        { staticClass: "twomargin" },
        [
          _c("span", [_vm._v("第二次上传图片：")]),
          _vm._l(_vm.one, function (imgdata) {
            return _c(
              "el-col",
              { key: imgdata.id, attrs: { span: 7 } },
              [_c("el-image", { attrs: { src: imgdata.pictureUrl } })],
              1
            )
          }),
        ],
        2
      ),
      _c(
        "el-row",
        [
          _c(
            "el-col",
            { attrs: { span: 8 } },
            [
              _c("span", [_vm._v("最终审核结果：")]),
              _c(
                "el-select",
                {
                  attrs: { placeholder: "请选择" },
                  model: {
                    value: _vm.value,
                    callback: function ($$v) {
                      _vm.value = $$v
                    },
                    expression: "value",
                  },
                },
                _vm._l(_vm.options, function (item) {
                  return _c("el-option", {
                    key: item.value,
                    attrs: { label: item.label, value: item.value },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c("el-col", { attrs: { span: 1 } }, [
            _c("span", [_vm._v("备注：")]),
          ]),
          _c(
            "el-col",
            { attrs: { span: 15 } },
            [
              _c("el-input", {
                attrs: {
                  type: "textarea",
                  rows: 5,
                  placeholder: "请确认最终审核结果",
                },
                model: {
                  value: _vm.textarea,
                  callback: function ($$v) {
                    _vm.textarea = $$v
                  },
                  expression: "textarea",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
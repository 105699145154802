<!--
 /*
 * @Author: yy
 * @Date: 2021-10-25 17:13
 * @Last Modified by: yy
 * @Last Modified time: 2021-10-25 17:13
 */
-->
<script>
import TablePage from '@/found/components/table_page';
import request from '@/found/utils/request';
import Storage from '@/found/utils/storage';
import Form from './form';

export default {
  extends: TablePage,
  components: {
    Form,
  },
  data() {
    return {
      requestUrl: '/cps/scanCodeRecordFactoryExt/page',
      params: {},
      formConfig: {},
      modalConfig: {},
    };
  },
  async created() {
    await this.getConfigList('cps-web-warehousing-overview-list');
  },
  mounted() {},
  methods: {
    setColumn(v) {
      const rowData = v;
      if (rowData.field === 'warehousingProportion') {
        rowData.formatter = ({ row }) => {
          if (!row.warehouseInCount || !row.orderNum) {
            return '';
          }
          return `${(
            Number(row.warehouseInCount) / Number(row.orderNum)
          ).toFixed(2)}`;
        };
      }
      if (rowData.field === 'warehouseTime') {
        rowData.formatter = ({ row }) => {
          if (row.feeCreateTime && row.dmsOrderTime) {
            // 时间格式
            return `${
              this.datetimeToUnix(row.feeCreateTime)
                - this.datetimeToUnix(row.dmsOrderTime)
              >= 0
                ? row.feeCreateTime
                : row.dmsOrderTime
            }`;
          }
          return `${row.feeCreateTime || row.dmsOrderTime || ''}`;
        };
      }
      if (rowData.field === 'warehouseStatus') {
        rowData.formatter = ({ row }) => {
          if (row.feeCreateTime && row.dmsOrderTime) {
            if (
              this.datetimeToUnix(row.feeCreateTime)
                - this.datetimeToUnix(row.dmsOrderTime)
              >= 0
            ) {
              if (!row.feeCreateState || !row.feeCreateState === 'N') {
                return '否';
              }
              return `${
                row.feeCreateState === 'system' ? '系统生成' : '手动生成'
              }`;
            }
            if (!row.dmsOrderState || !row.dmsOrderState === 'N') {
              return '否';
            }
            return `${
              row.dmsOrderState === 'system' ? '系统生成' : '手动生成'
            }`;
          }
          return `${row.warehouseStatus || row.warehouseStatus || ''}`;
        };
      }
      if (rowData.field === 'feeCreateState') {
        rowData.formatter = ({ row }) => {
          if (!row.feeCreateState || !row.feeCreateState === 'N') {
            return '否';
          }
          return `${row.feeCreateState === 'system' ? '系统生成' : '手动生成'}`;
        };
      }
      if (rowData.field === 'dmsOrderState') {
        rowData.formatter = ({ row }) => {
          if (!row.dmsOrderState || !row.dmsOrderState === 'N') {
            return '否';
          }
          return `${row.dmsOrderState === 'system' ? '系统生成' : '手动生成'}`;
        };
      }
      return rowData;
    },
    modalClick({ val, row }) {
      switch (val.code) {
        case 'confirm_the_inventory':
          this.sign(row.orderNo);
          break;
        case 'scan_detail':
          this.formName = 'Form';
          this.formConfig.code = 'view';
          this.formConfig.title = '扫码详情';
          this.modalConfig.title = '扫码详情';
          this.formConfig.row = row;
          sessionStorage.setItem('barCode', row.barCode);
          this.openModal();
          break;
        default:
          break;
      }
    },
    sign(id) {
      let orderNos = [];
      if (id) orderNos = [id];
      // else ids = this.selection;
      // if (!ids.length) return this.$message.warning('选择需要签署的协议');
      this.$confirm('确认入库?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          request
            .post('/cps/scanCodeRecordFactoryExt/feeCreate', orderNos)
            .then((res) => {
              if (res.success) {
                this.$message.success('操作成功');
                this.getList();
              }
            });
        })
        .catch(() => false);
    },
    datetimeToUnix(datetime) {
      let tmpDatetime = datetime.replace(/:/g, '-');
      tmpDatetime = tmpDatetime.replace(/ /g, '-');
      const arr = tmpDatetime.split('-');
      const now = new Date(
        Date.UTC(arr[0], arr[1] - 1, arr[2], arr[3] - 8, arr[4], arr[5]),
      );
      return parseInt(now.getTime() / 1000, 0);
    },
  },
};
</script>

<script>
import Form, { formCreate } from '../../../../../../components/form';
import request from '../../../../../../utils/request.js';

export default {
  extends: Form,
  components: {
    formCreate: formCreate.$form(),
  },
  data() {
    return {
      rule: [], // 渲染表单

    };
  },
  async created() {
    const res = await this.getFormRule('pic_list_form');
    // console.log(res);
  },
  methods: {
    formComplete() {
    //   this.getDetail();
      console.log(this.formConfig);
      this.setValue({
        // picList: ['http://www.baidu.com/img/bdlogo.png', 'http://rongcloud-web.qiniudn.com/docs_demo_rongcloud_logo.png'],
        picList: this.formConfig.imgUrls || [],
      });
    },

  },
};
</script>

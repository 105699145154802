<!--
/*
 * @Author: yy
 * @Date: 2021-10-25 17:13
 * @Last Modified by: yy
 * @Last Modified time: 2021-10-25 17:13
 */
-->

<script>
import request from '@/found/utils/request';
import Form, { formCreate } from '@/found/components/form';
import productTable from './table.vue';

formCreate.component('productTable', productTable);

export default {
  extends: Form,
  components: {
    formCreate: formCreate.$form(),
  },
  data() {
    return {
      formFunctionCode: 'cps-web-warehousing-overview-form',
    };
  },

  methods: {
    // 表单渲染完成后调用
    formComplete() {
      // this.getDetailInfo();
    },
    // 表单提交
    submit() {
    },
    // 获取协议详情
    getDetailInfo() {
      request.get('/cps/v1/agreement/agreement/findById', { id: this.formConfig.row.id }).then((res) => {
        if (!res.success) return this.$message.error(res.message);
        if (!res.result) return this.$message.warning('暂无相关协议文本');
        this.getRule('treetyTxt').props.treetyTxt = res.result;
      });
    },
  },
};
</script>

/*
 * @Author: 冯杰
 * @Date: 2021-08-06 09:35:47
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2021-09-09 19:51:14
 * 人工审核
 */
import Form, { formCreate } from '@/found/components/form';
import request from '@/found/utils/request';

import Policy from './components/policy.vue';
import DisplayImg from './components/displayimg.vue';

formCreate.component('Policy', Policy);
formCreate.component('DisplayImg', DisplayImg);

export default {
  extends: Form,
  components: {
    formCreate: formCreate.$form(),
  },
  data() {
    return {
      rule: [],
      formFunctionCode: 'manualReviewForm',
      displayData: {},
      fields: [
        'businessCode',
        'terminalCode',
        'terminalName',
        'taskName',
        'taskStatus',
        'taskStartTime',
        'taskEndTime',
        'orgName',
        'channelName',
        'provinceName',
        'cityName',
        'districtName',
      ],
    };
  },
  methods: {
    formComplete() {
      this.disabled(true, this.fields);
      this.getDisplayTaskData();
    },
    getDisplayTaskData() {
      request
        .get('/cps/v1/display/task/findByBusinessCode', {
          businessCode: this.formConfig.businessCode,
        })
        .then((res) => {
          if (!res.success) return;
          this.displayData = res.result;
          this.displayData.channelName = res.result.terminalBaseVo.channelName;
          this.displayData.orgName = res.result.terminalBaseVo.orgName;
          this.displayData.cityName = res.result.terminalBaseVo.cityName;
          this.displayData.districtName = res.result.terminalBaseVo.districtName;
          this.displayData.provinceName = res.result.terminalBaseVo.provinceName;
          this.setValue({ ...this.displayData });
          this.getRule('Policy').props.rowData = this.displayData;
          this.getRule('DisplayImg').props.rowData = this.displayData;
        });
    },
    submit() {
      const imgEl = this.fApi.el('DisplayImg');
      const formData = {
        remark: imgEl.textarea,
        finalAuditResult: imgEl.value,
        businessCode: imgEl.rowData.businessCode,
      };
      if (formData) {
        const url = '/cps/v1/display/task/updateTaskStatus';
        const params = { ...formData };

        request.patch(url, params).then((res) => {
          if (res.success) {
            this.$message({
              type: 'success',
              message: '操作成功',
            });
            this.$emit('onClose');
            this.$emit('onGetList');
          }
        });
      }
    },
  },
};

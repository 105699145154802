<!--
 /*
 * @Author: yy
 * @Date: 2021-10-25 17:13
 * @Last Modified by: yy
 * @Last Modified time: 2021-10-25 17:13
 */
-->
<script>
import TablePage from '@/found/components/table_page';
import request from '@/found/utils/request';
// import Storage from '@/found/utils/storage';

export default {
  extends: TablePage,

  data() {
    return {
      requestUrl: '/cps/scanCodeRecordFactoryExt/recordPage',
      params: { barCode: sessionStorage.getItem('barCode') },
      modalPageHeight: 400,
      tablePage: {
        // 分页
        currentPage: 1,
        pageSize: 10,
        totalResult: 0,
      },
    };
  },
  watch: {

  },
  async created() {
    await this.getConfigList('cps-web-warehousing-overview-product-list');
  },
  mounted() {},
  methods: {},
};
</script>

<!--
 * @Author: 冯杰
 * @Date: 2021-08-11 18:08:25
 * @LastEditTime: 2021-10-15 11:28:39
 * @FileName: 陈列照片
-->
<template>
  <div class="box">
    <!-- <div class="txalri">
          <span class="textShen">AI审核结果：</span>
          <span class="result">{{aiResult}}</span>
      </div> -->
    <div class="minBox">
      <div class="textclass">
        <span>第一次上传图片</span>
        <span>{{oneTime}}</span>
      </div>
      <div class="imgFlex">
        <el-image fit="scale-down" :preview-src-list="one" :src="img" v-for="img in one" :key="img.id">
          <div slot="placeholder" class="image-slot">加载中<span class="dot">...</span></div>
        </el-image>
      </div>
    </div>
    <div class="minBox">
      <div :class="two.length === 0 ?imgExhibit='nullImg':imgExhibit='noNone'+' textclass'">
        <span>第二次上传图片</span>
        <span>{{twoTime}}</span>
      </div>
      <div class="imgFlex">
        <el-image fit="scale-down" :preview-src-list="two" :src="img" v-for="img in two" :key="img.id" >
          <div slot="placeholder" class="image-slot">加载中<span class="dot">...</span></div>
        </el-image>
      </div>
    </div>
  </div>
</template>

<script>
import request from '@/found/utils/request';

export default {
  data() {
    return {
      one: [],
      two: [],
      aiResult: '',
      imgExhibit: 'noNone',
      oneTime: '',
      twoTime: '',
    };
  },
  props: {
    code: String,
  },
  mounted() {
    this.getImgList();
  },
  methods: {
    getImgList() {
      request.get('/cps/v1/display/task/findPictureByBusinessCode', { businessCode: this.code }).then((res) => {
        if (!res.success) return;
        console.log('陈列照片', res);
        res.result.displayTaskUploads.map((v) => {
          const item = v;
          if (item.uploadNumber === 1) {
            // 第一次
            this.oneTime = item.createTime;
            this.one.push(item.pictureUrl);
          } else {
            // 第二次
            this.twoTime = item.createTime;
            this.two.push(item.pictureUrl);
          }
          return item;
        });
        this.aiResult = res.result.aiAuditResult;
        if (this.aiResult === '3') {
          this.aiResult = '驳回';
        } else if (this.aiResult === '2') {
          this.aiResult = '驳回';
        } else if (this.aiResult === '1') {
          this.aiResult = '合格';
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.nullImg{
  display: none;
}
.noNone{
  display: block;
}
.imgFlex{
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 140px;
    margin-bottom: 20px;
    // margin-left: 120px;
}
.box{
    padding: 0 50px;
}

.textclass{
  display: flex;
  justify-content: space-between;
    span{
      height: 22px;
      line-height: 22px;
      font-family: PingFangSC-Medium;
      color: #333333;
      font-size: 16px;
      font-weight: 500;
      margin-bottom: 20px;
    }
    span::nth-child(1){
      text-align: right;
    }
}
.el-image{
    width: 140px;
    height: 140px;
}
.textShen{
    font-family: PingFangSC-Regular;
    color: #999999;
    font-size: 16px;
}
.result{
    font-family: PingFangSC-Regular;
    color: #F66046;
    font-size: 16px;
    font-weight: 500;
}
.txalri{
    height: 22px;
    line-height: 22px;
    text-align: right;
    margin-bottom: 24px;
}
</style>
